import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Button,
  Modal,
  Box,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import urls from "../config";
import { useAuth } from "../Context/authContext";
import { useAxios } from "../Context/useAxios";
import { useAlert } from "../Context/useSnackbar";
import { tokens } from "../theme";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

function MyAccount() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, logout } = useAuth();
  const [name, setName] = useState("");
  const [erp, setErp] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [employee, setEmployee] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const username = localStorage.getItem("username");
  const [passwordValidation, setPasswordValidation] = useState({
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialChar: false,
    notSameAsErp: true,
    isValid: false,
  });
  const { axios } = useAxios();
  const alert = useAlert();
  const navigate = useNavigate();

  const fetchEmployeeDetails = () => {
    axios
      .get(`${urls.employee}`, {
        params: { username: username },
      })
      .then((response) => {
        let resp = response.data;
        if (resp.status_code === 200) {
          setEmployee(resp.result[0]);
          setName(resp.result[0]?.name);
          setErp(resp.result[0]?.erp_id);
          setEmail(resp.result[0]?.email);
          setPhone(resp.result[0]?.mobile);
        } else {
          alert.error(resp.description);
        }
      })
      .catch((error) => {
        alert.error("Failed to fetch employee details");
      });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    const updatedData = {
      id: employee.id,
      erp_id: erp,
      name: name,
      email: email,
      mobile: phone,
      user: employee.user,
    };

    axios
      .put(`${urls.employee}`, updatedData)
      .then((response) => {
        if (response.data.status_code === 200) {
          alert.success("Profile updated successfully");
          setIsEditing(false);
        } else {
          alert.error(response.data.description);
        }
      })
      .catch((error) => {
        alert.error("Failed to update profile");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const validatePassword = (password, erpId) => {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const notSameAsErp = password !== erpId;

    return {
      hasMinLength,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialChar,
      notSameAsErp,
      isValid:
        hasMinLength &&
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialChar &&
        notSameAsErp,
    };
  };

  const handleGetOtp = () => {
    setIsSendingOtp(true);
    axios
      .post(`${urls.changePassword}`)
      .then((response) => {
        if (response.data.status_code === 200) {
          setShowOtpInput(true);
          alert.success(
            response?.data?.message ??
              "OTP sent successfully to your registered mobile number"
          );
        } else {
          alert.error(response?.data?.message ?? "Failed to send OTP");
        }
      })
      .catch((error) => {
        alert.error(error?.response?.data?.message ?? "Failed to send OTP");
      })
      .finally(() => {
        setIsSendingOtp(false);
      });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    setPasswordValidation(validatePassword(newPassword, erp));
  };

  const handlePasswordUpdate = () => {
    if (newPassword !== confirmPassword) {
      alert.error("Passwords don't match");
      return;
    }
    setIsPasswordUpdating(true);
    axios
      .put(`${urls.changePassword}`, {
        otp: otp,
        password: newPassword,
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          alert.success("Password updated successfully");
          setOpenPasswordModal(false);
          setOtp("");
          setNewPassword("");
          setConfirmPassword("");
          setShowOtpInput(false);

          setTimeout(() => {
            logout();
            navigate("/login");
          }, 1500);
        } else {
          alert.error(response?.data?.message || "Failed to update password");
        }
      })
      .catch((error) => {
        alert.error("Failed to update password");
      })
      .finally(() => {
        setIsPasswordUpdating(false);
      });
  };
  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  return (
    <Box sx={{ p: 3, maxWidth: "800px", margin: "0 auto" }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        {/* Header Section */}
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
          <AccountCircleIcon
            sx={{
              fontSize: 100,
              color: "primary.main",
              mb: 2,
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "text.primary",
              mb: 1,
            }}
          >
            Profile Details
          </Typography>
          <Divider sx={{ width: "100%", mt: 2 }} />
        </Box>

        {/* Profile Form Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.secondary", fontWeight: 600 }}
            >
              Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ readOnly: !isEditing }}
              sx={{
                backgroundColor: isEditing ? "white" : "#f5f5f5",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "primary.main" : "grey.300",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.secondary", fontWeight: 600 }}
            >
              ERP ID
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={erp}
              onChange={(e) => setErp(e.target.value)}
              inputProps={{ readOnly: !isEditing }}
              sx={{
                backgroundColor: isEditing ? "white" : "#f5f5f5",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "primary.main" : "grey.300",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.secondary", fontWeight: 600 }}
            >
              Email ID
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ readOnly: !isEditing }}
              sx={{
                backgroundColor: isEditing ? "white" : "#f5f5f5",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "primary.main" : "grey.300",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 1, color: "text.secondary", fontWeight: 600 }}
            >
              Phone Number
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              inputProps={{ readOnly: !isEditing }}
              sx={{
                backgroundColor: isEditing ? "white" : "#f5f5f5",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: isEditing ? "primary.main" : "grey.300",
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Action Buttons */}
        <Box
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          {isEditing ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
                sx={{
                  minWidth: 150,
                  textTransform: "none",
                }}
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  backgroundColor: colors.blueAccent[400],
                  color: "white",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "SAVE CHANGES"
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsEditing(false);
                  fetchEmployeeDetails();
                }}
                sx={{
                  minWidth: 150,
                  textTransform: "none",
                }}
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  // backgroundColor: colors.blueAccent[400],
                  color: "grey",
                }}
              >
                CANCEL
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => setIsEditing(true)}
                sx={{
                  minWidth: 150,
                  textTransform: "none",
                }}
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  backgroundColor: colors.blueAccent[400],
                  color: "white",
                }}
              >
                EDIT PROFILE
              </Button>
              <Button
                variant="contained"
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  // fontWeight: "semibold",
                  backgroundColor: colors.blueAccent[400],
                  color: "white",
                }}
                onClick={() => setOpenPasswordModal(true)}
                sx={{
                  minWidth: 150,
                  textTransform: "none",
                }}
              >
                CHANGE PASSWORD
              </Button>
            </>
          )}
        </Box>
      </Paper>

      <Modal
        open={openPasswordModal}
        onClose={() => setOpenPasswordModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: showOtpInput
              ? { xs: "90%", sm: "90%", md: 800 }
              : { xs: "90%", sm: 400, md: 400 },
            maxHeight: { xs: "90vh", sm: "90vh", md: "auto" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            overflow: "auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: 600 }}
            >
              Change Password
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleGetOtp}
                  disabled={showOtpInput || isSendingOtp}
                  sx={{ textTransform: "none", fontWeight: 600 }}
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: "bold",
                    backgroundColor: colors.blueAccent[400],
                    color: "white",
                  }}
                >
                  {isSendingOtp ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Send OTP via SMS"
                  )}
                </Button>
              </Grid>
              {showOtpInput && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      helperText="OTP has been sent to your registered mobile number"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="New Password"
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      onChange={handlePasswordChange}
                      size="small"
                      error={newPassword !== "" && !passwordValidation.isValid}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 2,
                }}
              >
                {showOtpInput && (
                  <Button
                    variant="contained"
                    onClick={handlePasswordUpdate}
                    disabled={!passwordValidation.isValid || isPasswordUpdating}
                    sx={{ textTransform: "none" }}
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      backgroundColor: colors.blueAccent[400],
                      color: "white",
                    }}
                  >
                    {isPasswordUpdating ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "UPDATE PASSWORD"
                    )}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenPasswordModal(false);
                    setOtp("");
                    setNewPassword("");
                    setConfirmPassword("");
                    setShowOtpInput(false);
                    setShowNewPassword(false);
                    setShowConfirmPassword(false);
                  }}
                  sx={{ textTransform: "none", fontWeight: 600 }}
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Box>

          {showOtpInput && (
            <Box
              sx={{
                flex: { md: 1 },
                width: "100%",
                backgroundColor: "#f5f5f5",
                p: 2,
                borderRadius: 1,
                border: "1px solid #e0e0e0",
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                Password Requirements:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.hasMinLength
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Must contain at least 8 characters
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.hasUpperCase
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Must contain at least 1 uppercase letter
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.hasLowerCase
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Must contain at least 1 lowercase letter
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.hasNumber
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Must contain at least 1 number
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.hasSpecialChar
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Must contain at least 1 special character
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: passwordValidation.notSameAsErp
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  • Should not be same as your ERP ID
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default MyAccount;
